import React, { useEffect, useState } from 'react'

import { environment, VERSION_NUMBER } from '@/config'

import CustomText, { FontWeight, TextSize } from '../base/CustomText'
import SunetLogo from '../base/Icons/svg/Sunet'

const FullScreenLoader = ({ showMessage = true }): JSX.Element => {
  const [isTakingTooLong, setIsTakingTooLong] = useState(false)

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        setIsTakingTooLong(true)
      }, 5000)
    }
  }, [])

  return (
    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-background">
      <div className="flex flex-col items-center relative w-full">
        <SunetLogo height={50} />
        {isTakingTooLong ? (
          <CustomText
            className="text-center absolute bottom-0 pt-8 transform translate-y-full"
            size={TextSize.Paragraph}
            weight={FontWeight.Normal}
          >
            Está tardando mucho tiempo. Comprueba tu conexión a internet o contacta a soporte.
          </CustomText>
        ) : null}
      </div>

      <div className="absolute bottom-0 left-0 w-full px-8 text-center">
        <CustomText size={TextSize.Caption} weight={FontWeight.Normal}>
          {environment} Versión {VERSION_NUMBER || '0.0.0'}
        </CustomText>
      </div>
    </div>
  )
}

export default FullScreenLoader
