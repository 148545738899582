import Image from 'next/image'
import { FaArrowRight } from 'react-icons/fa'

import Mobile from '@/public/static/images/landing/halfphone.png'

import Button, { ButtonAppearance, ButtonSize } from '../base/Button'
import Container from '../base/Container'
import CustomText from '../base/CustomText'

export const AppDownload = () => {
  return (
    <Container maxWidth={1200}>
      <section className="w-full flex flex-col md:flex-row items-center md:items-stretch justify-center md:justify-between bg-secondary rounded-3xl overflow-hidden">
        <div className="p-8 md:p-12 md:pl-24 space-y-12 text-white w-full md:w-1/2 z-20">
          <CustomText className="!text-5xl !font-bold">¡Descarga la App!</CustomText>
          <CustomText className="!text-xl">
            Sunet es aún mejor si instalas la app en tu teléfono. Mantente al día y colabora.
          </CustomText>
          <a href="https://onelink.to/etk387" className="">
            <Button appearance={ButtonAppearance.Primary} size={ButtonSize.Big} className="rounded-xl mt-6">
              <span className="flex items-center gap-2">
                <span>Descarga ahora</span>
                <FaArrowRight />
              </span>
            </Button>
          </a>
        </div>
        <div className="relative flex">
          <div
            style={{ zIndex: 0 }}
            className="bg-primary h-[250px] w-[250px] absolute rounded-full -left-[100px] -bottom-[100px] md:left-auto md:bottom-auto  md:-top-[100px] md:-right-[70px]"
          />
          <div
            style={{ zIndex: 0 }}
            className="bg-accent-2 h-[250px] w-[250px] absolute rounded-full -top-[100px] -right-[70px] md:top-auto md:right-auto md:-bottom-[100px] md:-left-[100px] flex items-center justify-center"
          >
            <div className="bg-secondary h-[125px] w-[125px] rounded-full" />
          </div>
          <Image
            style={{ zIndex: 20, maxWidth: 400 }}
            alt="Sunet mobile app"
            className="self-end px-8 md:px-0 md:pt-8 md:pr-24 z-50 w-full"
            src={Mobile}
          />
        </div>
      </section>
    </Container>
  )
}
