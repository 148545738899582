export const SITE_NAME = 'Sunet'
export const SITE_DESCRIPTION = ''

export const environment = process.env.APP_ENV || 'local'

console.log('Environment:', environment)

export const isProductionENV = process.env.APP_ENV === 'production'

export const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL
export const VERSION_NUMBER = process.env.NEXT_PUBLIC_VERSION_NUMBER

export const webinarChatURL = process.env.NEXT_PUBLIC_WEBINAR_URL
export const panelURL = process.env.NEXT_PUBLIC_PANEL_URL

export const API_URL = process.env.NEXT_PUBLIC_API_URL
export const API_URL_TEST = process.env.NEXT_PUBLIC_TEST_API_URL

export const BASE_DOMAIN = process.env.NEXT_PUBLIC_BASE_DOMAIN

export const CHAT_URL = process.env.NEXT_PUBLIC_CHAT_URL
// export const CHAT_URL = "http://localhost:3000"
export const googleAppsAPIKey = process.env.NEXT_PUBLIC_GOOGLE_APPS_KEY

export const GOOGLE_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GOOGLE_MEASUREMENT_ID

// DEV/LOCAL - MUX TRAXO  PROD - MUX NETMIN
export const muxEnvKey = process.env.NEXT_PUBLIC_MUX_ENV_KEY

export const linkedInClientID = '78p5tn807pf43u'
export const linkedInScope = 'profile email openid'
export const linkedInState = 'bmV0bWluTGlua2VkaW5Mb2dpbg'

export const facebookClientID = '464601666428574'

export const googleClientID = '1054491375169-n7v4gsjeuh2tir0vdrqvshfupbma2tf2.apps.googleusercontent.com'

// Array of regular expressions that defines which routes must be opened as modals when
// the navigation event occurs within the web app
// In other words: When you click a link, a modal shows the route, but if you refresh, the route uses the entire viewport.
export const modalRoutes = [
  '^\\/p\\/[0-9a-f]{8}\\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\\b[0-9a-f]{12}', // Match: /p/[uuid]
  '^\\/a\\/[0-9]{4}\\/[0-9]{2}\\/[0-9]{1,2}', // Match: /a/[year]/[month/[day]
  // Match: /achievements/[uuid] '^\\/achievements\\/[0-9a-f]{8}\\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\\b[0-9a-f]{12}',
]
