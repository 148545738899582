import Image from 'next/image'
import Link from 'next/link'
import { useState } from 'react'
import { FaBars } from 'react-icons/fa'

import SunetLogo from '@/public/Sunet.png'
import { scrollToVideo } from '@/utils/landing'

import Button, { ButtonSize } from '../base/Button'
import Container from '../base/Container'
import VerticalReveal from '../shared/VerticalReveal'
import { Rocket } from './Rocket'

export const Navbar = () => {
  const [open, setOpen] = useState(false)

  const openVideo = () => {
    setOpen(false)
    scrollToVideo()
  }

  return (
    <nav className="bg-[#F8F9FF]">
      <Container maxWidth={1200} className="w-full flex justify-between py-2 md:py-4 items-center">
        <div className="flex items-center justify-center relative rounded-2xl overflow-hidden-z h-16 bg-brand">
          <Image src={SunetLogo} alt={`Política de Privacidad`} className="h-10 md:h-11 w-auto" />
        </div>
        <div className="space-x-6 items-center md:flex hidden">
          <Link className="text-accent-1" href={'/auth/sign-in'}>
            Iniciar Sesión
          </Link>
          <Button className="!bg-[#E5F4F2] rounded-xl !text-primary" size={ButtonSize.Big} onClick={scrollToVideo}>
            Ver video
          </Button>
          <a href="https://onelink.to/etk387">
            <Button size={ButtonSize.Big} className="rounded-xl">
              <span className="flex gap-1 items-center">
                <Rocket />
                <span>Descargar App</span>
              </span>
            </Button>
          </a>
        </div>
        <div className="md:hidden">
          <button
            onClick={() => {
              setOpen(!open)
            }}
            type="button"
            className="inline-flex items-center w-10 h-10 justify-center text-xl rounded-lg md:hidden hover:bg-[#E5F4F2] focus:outline-none focus:ring-2 focus:ring-primary"
          >
            <FaBars className="text-primary" />
          </button>
        </div>
      </Container>
      <VerticalReveal open={open}>
        <div className="gap-8 flex md:hidden flex-col items-center">
          <Link className="text-accent-1" href={'/auth/sign-in'}>
            Iniciar Sesión
          </Link>
          <Button onClick={openVideo} className="!bg-[#E5F4F2] rounded-xl !text-primary" size={ButtonSize.Big}>
            Ver video
          </Button>
          <a href="https://onelink.to/etk387">
            <Button size={ButtonSize.Big} className="rounded-xl">
              <span className="flex gap-1 items-center">
                <Rocket />
                <span>Descargar App</span>
              </span>
            </Button>
          </a>
        </div>
      </VerticalReveal>
    </nav>
  )
}
